import { cssVw } from './cssUnits'

const sizes = {
    3 : {
        default: 25,
        selected: 50,
    },
    5 : {
        default: 15.75, // 15.75 * 4 = 63
        selected: 37,
    },
    7 : {
        default: 10.5,
        selected: 37,
    }
}



function getColumnWidth(columnCount, isSelected, indent=34){

    const containerSize = cssVw * 100 - indent

    let type = isSelected ? "selected" : "default"
    let size = sizes[columnCount][type]

    let percent = size * containerSize / 100 

    if (isSelected) {
        console.log('isSelected :>> ',  Math.round(percent) );
    }

    return Math.round(percent) 
}

export {
    getColumnWidth
}
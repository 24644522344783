import Vue from 'vue'
import App from './App.vue'

import { vfmPlugin } from 'vue-final-modal'
import VueRouter from "vue-router"
import Vue2TouchEvents from 'vue2-touch-events'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'

import router from './router.js'
import store from './store/store.js';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
// Global styles
Vue.use(Buefy)

// Photo cropp
Vue.use(VueCroppie);

// Color picker
Vue.use(ColorPanel)
Vue.use(ColorPicker)

// Modal windows
Vue.use(vfmPlugin)

// Vue router
Vue.use(VueRouter)
Vue.config.productionTip = false

// Swipes and touches
Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: "",
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400,
});


// Beautiful alerts
Vue.use(VueSweetalert2);

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'

class Alert {
    /**
     * @param {string} text Your alert message
     */
    static fire(text) {
        Swal.fire({
            title: 'Ошибка',
            text: text,
            position: 'top-end',
            showConfirmButton: false,
            padding: "5px 15px",
            width: "100%",
            timer: 4000,
            icon: 'error',
            toast: true,
            heightAuto: false,
        })
    }
}

export default Alert
function findStringBetween(string, first, second){
    let chars = string.split('')
    let result = []

    let isFindFirst = false
    let isFindSecond = false

    chars.forEach(char => {
        
        if (!isFindFirst && char == first){
            isFindFirst = true
            return
        }

        if (!isFindSecond && char == second){
            isFindSecond = true
            return
        }

        if (isFindFirst && !isFindSecond){
            result.push(char)
        }
    });

    return result.join('')
}

function limitedString(str, limit){
    if (str.length <= limit) return str

    let limitString = str.slice(0, limit) + "..."
    return limitString
}

export {findStringBetween, limitedString}
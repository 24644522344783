import CMS from "../../service/cms/service"

const getDefaultState = () => {
    return {
        timezones: [],
        countries: [],
    }
}

export default {
    state : getDefaultState(),
    mutations: {
        'set-timezones': (state, timezones) => {
            state.timezones = timezones
        },
        'set-countries': (state, countries) => {
            state.countries = countries
        },
    },
    getters: {
        timezones(state){
            return state.timezones
        },
        getTimezoneByID: (state) => (id) => {
            return state.timezones.find( timezone => timezone.id == id)
        },  
        getCountyByID: (state) => (id) => {
            return state.countries.find( county => county.id == id)
        },  
        countries(state){
            return state.countries
        },

        browserTimezone(state){
            let tzShortName = Intl.DateTimeFormat().resolvedOptions().timeZone //  Europe/Moscow only
            let currentTimezone = state.timezones.find(tz => tz.name.includes(tzShortName))

            // If timezone is not found , make it by yourself
            if (currentTimezone == undefined) {
                let diffTZ = (new Date()).getTimezoneOffset() * (-1)
                let hourOffset = Math.abs(Math.floor(diffTZ / 60) )
                let minuteOffset = Math.abs(diffTZ - hourOffset * 60)
                let negative = diffTZ > 0 ? "+" : "-"
                if (hourOffset < 10) {
                    hourOffset = "0" + hourOffset
                }
                if (minuteOffset < 10) {
                    minuteOffset = "0" + minuteOffset
                }
                currentTimezone = `${tzShortName} (UTC ${negative}${hourOffset}:${minuteOffset})`
                return { 
                    name: currentTimezone,
                    id: -1
                }
            }

            return currentTimezone
        }
    },
    actions: { 
        'setup-geo': (context, payload) => {
            console.log('setup-geo')
            CMS.geo.timezoneList().then(timezones => {
                if (timezones.err){
                    throw new Error(timezones.err)
                }
                context.commit("set-timezones", timezones)
            })

            CMS.geo.countryList().then(countries => {
                if (countries.err){
                    throw new Error(countries.err)
                }
                context.commit("set-countries", countries)
            })
        },
    }
}
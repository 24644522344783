<template>
    <div class="main__layout">
        <Header/>
        <div id="layout__content">
            <slot></slot>
            <SlotCreate/>
        </div>
        <StaticMenu v-if="isLogin && navBarIsNeeded()" />
    </div>
</template>

<script>
import Header from '../components/header/Header.vue';
export default {
    name: "MainLayout",
    components: {
        Header,
        SlotCreate : () => import('./SlotCreate.vue'),
        StaticMenu : () => import('../components/static-menu/StaticMenu.vue'),
    },
    computed: {
        isLogin(){
            return this.$store.getters.userIsLogin 
        }
    },
    methods: {
        navBarIsNeeded(){
            let result = true
            return result
        }
    },
}
</script>

<style scoped>
#layout__content{
    margin: 32px 0 0 0;
    height: calc(100vh - 32px - 50px);
    overflow-y: auto;
    position: relative;
}
</style>
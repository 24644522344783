import router from "../../router"
import CMS from "../../service/cms/service"
import {
	NONE_MEMBERSHIP_LEVEL,
	FOLLOWER_MEMBERSHIP_LEVEL,
} from "../../scripts/membershipLevels.js"

const getDefaultState = () => {
    return {
        templates: [],
		hosts: [],
		ratings: [],
		prices: [],
    }
}

export default {
	state: getDefaultState(),
	mutations: {
		'update-slot-templates': (state, templates) =>  state.templates = templates ?? [],
		'update-slot-ratings': (state, ratings) =>  state.ratings = ratings ?? [],
		'update-slot-prices': (state, prices) =>  state.prices = prices ?? [],
		'update-slot-hosts': (state, hosts) =>  state.hosts = hosts ?? [],

		'add-template-item': (state, template) => state.templates.push(template),
		
		'add-rating-item': (state, rating) => state.ratings.push(rating),
		'del-rating-item': (state, ratingId) => state.ratings = state.ratings.filter(rating => rating.id != ratingId),
		'upd-rating-item': (state, rating) => {
			let updatedRatingIndex = state.ratings.findIndex(r => r.id == rating.id)
			state.ratings[updatedRatingIndex] = rating
		},
		'add-price-item': (state, price) => state.prices.push(price),
		'del-price-item': (state, priceId) => state.prices = state.prices.filter(price => price.id != priceId),
		'upd-price-item': (state, price) => {
			let updatedPriceIndex = state.prices.findIndex(pr => pr.id == price.id)
			state.prices[updatedPriceIndex] = price
		},
	},
	getters: {
		calendarHosts(state){
			return state.hosts
		},
		calendarRatings(state){
			return state.ratings.sort((a, b) => a.val - b.val)
 		},
		calendarPriceLevels(state){
			return state.prices
		},
		calendarSlotTemplates(state){
			return state.templates
		},
		calendarSlotTemplateById(state){
			return (templateId) => {
				return state.templates.find(t => templateId == t.id)
			}
		},
	},
	actions: {
		'setup-slot-templates': (store, templates=[]) => {

			let calendarUid = store.getters.calendarUid
			if (calendarUid == "") 
				calendarUid = store.getters.getDefaultCalendar
			
			let membershipLevel = store.getters.getMembershipLevel 
			if (calendarUid != store.getters.getDefaultCalendar &&
				membershipLevel == NONE_MEMBERSHIP_LEVEL || 
				membershipLevel == FOLLOWER_MEMBERSHIP_LEVEL)
				return
			
			if (templates.length != 0){
				store.commit('update-slot-templates', templates)
				return
			}

			return CMS.template.getAll(calendarUid).then(data => {
				if (data.err){
					throw data.err
				}
				store.commit('update-slot-templates', data.templates)
				store.commit('update-slot-ratings', data.ratings)
				store.commit('update-slot-prices', data.levels)
				store.commit('update-slot-hosts', data.hosts)
				return data
			})
		},
		'add-template-item': (store, template) => store.commit('add-template-item', template),
		
		'add-rating-item': (store, rating) => store.commit('add-rating-item', rating),
		'del-rating-item': (store, ratingId) => store.commit('del-rating-item', ratingId),
		'upd-rating-item': (store, rating) => store.commit('upd-rating-item', rating),

		'add-price-item': (store, rating) => store.commit('add-price-item', rating),
		'del-price-item': (store, ratingId) => store.commit('del-price-item', ratingId),
		'upd-price-item': (store, rating) => store.commit('upd-price-item', rating),
	}
}
<template>
    <div class="personal_calendar_list">
        <BurgerMenu :show="show" :closer="closer">

            <!-- Title -->

            <div class="title__cont" v-if="title">
                {{title}}
            </div>

            <!-- Search field -->

            <div class="search__cont" v-if="calendars.length > 10">
                <span class="iconify" 
                    data-icon="ph:magnifying-glass-light" 
                    data-width="26" 
                    data-height="26">
                </span>
                <input type="text" @input="updateSearch" name="calendar" v-model="search">
                <div @click="clearSearch()">
                    <span class="iconify" 
                        data-icon="clarity:window-close-line" 
                        data-width="22"
                        style="color: red;"   
                        data-height="22">
                    </span>
                </div>
            </div>

            <!-- Calendar list -->

            <span class="item__cont" 
                :key="search"
                v-if="getItemsBySearch().length != 0">
                <Item 
                    v-for="(calendar, index) in getItemsBySearch()" 
                    :key="'second-menu-item-' + index"
                    :title="calendar.title"
                    :usage="() => selectCalendar(calendar.uid)"
                    :description="calendar.description"
                    :isSelected="isSelected(calendar.uid)"
                    :isDefault="calendar.isDefault"
                />
                <Item
                    v-if="!excludeCreate"
                    title="Create calendar"
                    icon="uil:plus"
                    :usage="createCalendar"
                />
            </span>
            <span class="err__cont" v-else>
                Calendars not found
            </span>
        </BurgerMenu>
    </div>

</template>

<script>
import router from '../../router'
import Item from './Item.vue'

import BurgerMenu from '../burger-menu/BurgerMenu.vue'

export default {
    name: 'PersonalCalendarList',
    components: {
        BurgerMenu,
        Item,
    },
    
    props: {
        show: Boolean,
        title: String,
        selector: Function,
        closer: Function,
        excludeCurrent: Boolean,
        excludeCreate: Boolean,
    },
    
    data(){
        return{
            calendars: this.$store.state.user.calendars.managed || [],
            search: "",
        }
    },

    methods: {
        createCalendar(){
            router.push({name:"create-calendar"})
        },
        isSelected(uid){
            let currentCalendarUid = this.$route.params.calendarUid
            return currentCalendarUid == uid
        },
        selectCalendar(calendarUid){
            this.closer()
            if (this.selector){
                return this.selector(calendarUid)
            }
            router.push({name: 'calendar', params: {calendarUid: calendarUid}})
        },
        getItemsBySearch(){
            let result = []
            let managedCalendars = this.calendars
            let managedCalendarsUid = Object.keys(managedCalendars)
            
            // Setup current (private) calendar to first position
            let currentCalendarUid = this.$route.params.calendarUid
            let currentCalendarIndex

            managedCalendarsUid.forEach( (uid, index) => {
                result.push(managedCalendars[uid])
                if (currentCalendarUid == uid){
                    currentCalendarIndex = index
                }
            })
            // If current calendar in list of managed calendars
            if (currentCalendarIndex){
                let oldFirstItem = result[0]
                result[0] = result[currentCalendarIndex]
                result[currentCalendarIndex] = oldFirstItem
            }


            if (this.excludeCurrent) {
                result = result.filter(calendar => calendar.uid != currentCalendarUid)
            }

            if (this.search == "") return result

            result = result.filter( calendar => {
                return calendar.title.toLowerCase().includes(this.search.toLowerCase())
            })

            return result
        },
        clearSearch(){
            this.search = ""
        },
        updateSearch(event){
            this.search = event.target.value
        },
    },

}
</script>

<style scoped>
.search__cont{
    display: flex;
    padding: 5px 17px;
    justify-content: center;
}

.title__cont {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.search__cont > input{
    font-size: 16px;
    width: 200px;
    
    border: none;
    border-bottom: 1px solid black;
    padding: 3px 5px;
}

.search__cont > input:focus{
    outline:none;
}
.item__cont{
    height: 246px;
    overflow-x: scroll;
    max-height: 220px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
/* Скрываем scrollbar */
.item__cont::-webkit-scrollbar {
  display: none;
}
 

.item__cont {
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}

.err__cont{
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    height: 140px;
}
</style>
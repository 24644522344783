<template>
    <div class="default_burger_menu">
        <BurgerMenu :show="show" :closer="closer">
            <BurgerMenuItem
                v-for="(item, index) in getItems()"
                :key="'menu-item-' + index"

                :title="item.title"
                :titleColor="item.titleColor"
                :titleStyle="item.titleStyle"

                :icon="item.icon"
                :iconColor="item.iconColor"
                :iconSize="item.iconSize"

                :usage="item.usage"/>
        </BurgerMenu>
    </div>
</template>

<script>
import BurgerMenu from '../burger-menu/BurgerMenu.vue';
import BurgerMenuItem from '../burger-menu/BurgerMenuItem.vue';

import AuthService from '../../service/auth-service';

export default {
    name: 'DefaultBurgerMenu',
    components:{
        BurgerMenu,
        BurgerMenuItem
    },
    props: {
        show: Boolean,
        closer: Function,
    },
    methods: {
        getItems(){
            return [
                {title: "Profile settings", icon: "fluent:settings-32-regular"},
                {title: "Favorite", icon:"akar-icons:star"},
                {title: "Slot history", icon: "bi:clock-history"},
                {title: "Qr-code", icon: "iconoir:scan-qr-code"},
                {title: "Payment history", icon:"bi:credit-card-2-front"},
                {
                    title: "Exit",
                    titleColor: "red",
                    icon: "bx:exit",
                    iconColor: "red",
                    usage: this.sighOut,
                }
            ]
        },
        async sighOut(){
            this.$store.dispatch("delete-user-data")
            AuthService.signOut()
        },
    },
};
</script>

<style scoped>

</style>
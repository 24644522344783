import YYYYMMDD, { isEqualDate } from '../../scripts/date'
import table from './calendar-table-store'
import Vue from 'vue'

const getDefaultState = () => {
    return {
        id: -1,
        uid: "",
        workdayCalendarId: -1,
        timezoneId: -1,
        timezoneName: "",
        visibilityLevelId: -1,
        isStrict: false,
        title: null,
        description: "",

        ratingTypeName: "",
        membershipLevel: -1,
        countryId: -1,
        selectedDate: YYYYMMDD(new Date()),
        columnCount: 7,
        columnScale: [1, 1, 1, 1, 1, 1, 1],
        currentScroll: 0,   
        socialLinks: {},
        restricted: null,

        isNotFound: false,
        isLoaded: false,
    }
}

export default {
    state : getDefaultState(),    
    modules: {
        table,
    },
    mutations: {
        'set-calendar-rating-type-name': (state, name) => {
            state.ratingTypeName = name 
        },
        'set-not-found': (state, value=true) => {
            state.isNotFound = value
        },
        'calendar-set-is-loaded': (state, value=true) => {
            state.isLoaded = value
        },
        'set-last-calendar': (state, payload) => {
            Object.assign(state, getDefaultState())
            Object.keys(payload).forEach(elem => {
                state[elem] = payload[elem]
            })
        },
        'update-scroll': (state, {currentScroll}) => {
            state.currentScroll = currentScroll
        },
        'set-calendar-props': (state, payload) => {
            let newSelectedDate = payload.selectedDate
            if (newSelectedDate){
                state.selectedDate = newSelectedDate  
            }

            let newColumnCount = payload.columnCount
            if (newColumnCount){
                state.columnCount = newColumnCount  
                state.columnScale = new Array(state.columnCount).fill(1)
            }
        },
        'set-calendar-column-scale': (state, payload) => {
            Object.keys(payload).forEach(columnIndex => {
                if (state.columnScale[Number(columnIndex)])
                    Vue.set(state.columnScale, Number(columnIndex), payload[columnIndex]) 
            })
        },
        'clear-calendar' : (state, _) => {
            Object.assign(state, getDefaultState())
        }
    },
    getters: {
        getTitle(state){
            return state.title
        },
        getMembershipLevel(state){
            return state.membershipLevel
        },
        calendarMainSettings(state){
            return state
        },
        calendarIsLoaded(state){
            return state.isLoaded
        },
        calendarUid(state){
            return state.uid
        },
        calendarIsNotFound(state){
            return state.isNotFound
        },
        calendarRatingTypeName(state){
            return state.ratingTypeName == ""?"Skill":state.ratingTypeName
        },
        calendarTitle(state){
            return state.title
        },
        calendarDescription(state){
            return state.description
        },
        calendarRestricted(state){
            return state.restricted
        },
        calendarSocialLinks(state){
            return state.socialLinks
        },
        calendarVisibilityLevel(state){
            return state.visibilityLevelId 
        },
        getTimezone(state, getters){
            let calendarUid = getters.calendarUid
            if (calendarUid == ""){
                let userTimezoneId = getters.userTimezoneId
                return getters.getTimezoneByID(userTimezoneId)
            }

            return {
                id: state.timezoneId,
                name: state.timezoneName,
            }
        },
        calendarProperties(state){
            return {
                selectedDate: state.selectedDate,
                columnCount: state.columnCount,
            }
        },
        currentScroll(state){
            return state.currentScroll
        }
    },
    actions: {

        'set-calendar-rating-type-name': (context, payload) => {
            context.commit('set-calendar-rating-type-name', payload)
        },
        'set-last-calendar': (context, payload) => {
            context.commit('set-last-calendar', payload)
        },
        'set-calendar-props': (context, payload) => {
            context.commit('set-calendar-props', payload)
        },
        'set-calendar-column-scale': (context, payload) => {
            context.commit('set-calendar-column-scale', payload)
        },
        'clear-calendar': ({commit, dispatch}, _) => {
            commit('clear-calendar')
            dispatch('clear-table')
        },
        'calendar-set-is-loaded': (context, payload) => context.commit('calendar-set-is-loaded', payload),
        'set-not-found': (context, payload) => context.commit('set-not-found', payload),
        'update-scroll': (context, payload) => context.commit('update-scroll', payload),
    }
}
import Vue from 'vue'

const IS_DEV = false 
/**
 * Broker of the events like original Vue broker, but with personal addition
 */
class EventBroker{
    #bus
    #eventRoutes
    waitList = {}
    constructor (name="") {
        this.name = name
        this.#bus = new Vue()
        this.#eventRoutes = {}
    }

    $on(type, endpoint, func){
        if(!this.#eventRoutes[type]){
            this.#eventRoutes[type] = {}
            if(IS_DEV) {console.log(`EventBroker ${this.name} : ${type} type was created`)}
        }

        if(this.#eventRoutes[type][endpoint]){
            if(IS_DEV) console.info(`EventBroker ${this.name}: event with endpoint ${endpoint} and type ${type} already exists`)
        }
        this.#eventRoutes[type][endpoint] = func

        this.#bus.$off(`${type}:${endpoint}`)
        this.#bus.$on(`${type}:${endpoint}`, func)

        if (this.waitList[type] && this.waitList[type][endpoint]) {
            let waiters = this.waitList[type][endpoint]
            waiters.forEach(waiter => waiter.stopSelfDestroy())
            waiters.forEach(waiter => waiter.doAction(func))
        }
        if(IS_DEV) {console.log(`EventBroker ${this.name} :`, type, "-->", endpoint)}
    }
    
    #deleteEvent(type, endpoint){
        this.checkEndpoint(type, endpoint)

        this.#bus.$off(`${type}:${endpoint}`)
        delete this.#eventRoutes[type][endpoint]
    }
    
    checkType(type){
        return this.#eventRoutes[type] != undefined
    }

    checkEndpoint(type, endpoint){
        return this.#eventRoutes[type][endpoint] != undefined
    }
    /**
     * 
     * @param {Event} event - event
     * @param {number} duration - duration
     */
    makeEventTypeWaiter(event, duration=5) {
        let type = event.type   
        let endpoint = event.endpoint
        let eventTypeWaiter = new EventWaiter(event)
        if (!this.waitList[type]) this.waitList[type] = {}
        if (!this.waitList[type][endpoint]) this.waitList[type][endpoint] = []

        this.waitList[type][endpoint].push(eventTypeWaiter)
        eventTypeWaiter.wait(duration, () => {
            this.waitList[type][endpoint] = this.waitList[type][endpoint]
                .filter(waiter => waiter != eventTypeWaiter)

            if (this.waitList[type][endpoint].length == 0) {
                delete this.waitList[type][endpoint]
            }

            if (this.waitList[type].length == 0){
                delete this.waitList[type]
            }
        })
    }

    $off(type, endpoint=""){
        let eventTypeIsExists = this.checkType(type)
        if (!eventTypeIsExists) return

        if (endpoint){
            this.#deleteEvent(type, endpoint)
            return
        }   
        
        Object.keys(this.#eventRoutes[type]).forEach(endpoint => {
            this.#deleteEvent(type, endpoint)
        })

        delete this.#eventRoutes[type]

        if(IS_DEV) {console.log(`EventBroker ${this.name} : ${type} type was deleted`)}
    }

    $emit(type, endpoint, data){
        let eventTypeIsExists = this.checkType(type)
        if (!eventTypeIsExists){
            console.log(`EventBroker ${this.name} : wait for event type ${type}`)
            console.log(`Current types :`, Object.keys(this.#eventRoutes))
            return this.makeEventTypeWaiter(new Event(type, endpoint, data))
        }

        let eventEndpointIsExists = this.checkEndpoint(type, endpoint)
        if (!eventEndpointIsExists){
            console.log(`EventBroker ${this.name} : wait for endpoint ${endpoint} of event type ${type}`)
            return this.makeEventTypeWaiter(new Event(type, endpoint, data))
        }

        this.#bus.$emit(`${type}:${endpoint}`, data)
    }
    /**
     * Remove all similar ones outside the arrayOfNames 
     * @param {Array} arrayOfNames - items to save
     * @param {string} template - template of all items
     * 
     * 
     */
    offUselessSimilarTypes(arrayOfNames, template){
        
        let typesToOff = Object.keys(this.#eventRoutes).filter(typeName => {
            let isTemplated = typeName.includes(template)

            if (isTemplated){
                return !arrayOfNames.includes(typeName)
            }

            return false
        })

        typesToOff.forEach(eventType => {
            this.$off(eventType)
        })
    }
    /**
     * Send event to all similar types 
     * @param {string} template - template of similar items
     * @param {string} endpoint - event endpoint to shapeInside
     * @param {Object} data - event data 
     */
    $emitSimilarTypes(template, endpoint, data, {senderType}){
        let typesToEmit = Object.keys(this.#eventRoutes).filter(typeName => typeName.includes(template))

        typesToEmit.forEach(typeName => {
            if (senderType && typeName == senderType) return 
            this.$emit(typeName, endpoint, data)
        })
    }
}

/**
 * Event of the EventBroker
 */
class Event {
    constructor (type, endpoint, data) {
        this.type = type
        this.endpoint = endpoint
        this.data = data
    }
}

/**
 * Event waiter for the EventBroker. Needed for the situations
 * when the event with type and endpoint was called, but it
 * not exist in the event storage. EventBroker give the promise
 * that event will be executed when it will be registered.
 * 
 * If the event is not registered during the 'lifeDuration' of the
 * EventWaiter, the instance of the EventWaiter will be destroyed.
 */
class EventWaiter {
    eventTypeIsRegistered = false
    removeFunction = null
    /**
     * @param {Event} eventData - event that need to wait
     */
    constructor (eventData) {
        this.event = eventData
        console.log(`EventWaiter : ${this.event.type} ${this.event.endpoint} created`);
    }
    /**
     * Stop to destroy the EventWaiter, when wait is over
     */
    stopSelfDestroy(){
        this.eventTypeIsRegistered = true
    }

    /**
     * Wait to register the event types
     * @param {number} timeDuration - time to wait in seconds
     * @param {Function} removeFunction - function to remove the instance of EventWaiter
     * @returns {Promise}
     */
    wait(timeDuration, removeFunction){
        setTimeout(() => {
            if (!this.eventTypeIsRegistered) {
                removeFunction()
                console.log(`EventWaiter : ${this.event.type} ${this.event.endpoint} destroyed`);
            }
        }, timeDuration * 1000)
    }

    /**
     * Do the action and after that remove the instance
     * @param {Function} action - event handler action
     */
    doAction(action) {
        return new Promise((resolve) => {
            action(this.event.data)
            resolve()
        }).then(() => {
            if (this.removeFunction){
                this.removeFunction()
                console.log(`EventWaiter : ${this.event.type} ${this.event.endpoint} destroyed`);
            }
        })
    }
}

export default EventBroker

import VueRouter from 'vue-router'

import YYYYMMDD from './scripts/date.js'

const routes = [{
        name: 'home',
        path: '/',
        component: () =>
            import ("./pages/Home.vue")
    },
    {
        name: 'auth',
        path: '/auth',
        component: () =>
            import ('./pages/Auth.vue')
    },
    {
        name: 'welcome',
        path: '/welcome',
        component: () =>
            import ('./pages/Welcome.vue')
    },

    {
        name: 'calendar-layout',
        path: '/',
        component: () => import ('./pages/CalendarPage.vue'),
        children: [
            {
                name: 'calendar',
                path: ':calendarUid',
                component: () => import ('./pages/Calendar.vue'),
                query: {
                    w: 7,
                    dt: YYYYMMDD(new Date()),
                },
            },
            {
                name: 'slot-edit',
                path: ':calendarUid/slots/:slotId(\\d+)/edit',
                component: () => import ('./pages/SlotEdit.vue')
            },
            {
                name: 'slot-attend',
                path: ':calendarUid/slots/:slotId(\\d+)',
                component: () => import ('./pages/SlotAttend.vue')
            },
            {
                name: 'calendar-settings',
                props: true,
                path: ':calendarUid/settings',
                component: () => 
                    import ('./pages/CalendarSettings.vue'),
                children: [
                    {
                        name: 'calendar-settings-menu',
                        path: 'menu',
                        component: () => import('./components/calendar/settings/MainMenu.vue')
                    },
                    {
                        name: 'calendar-settings-main',
                        path: 'main',
                        component: () => import('./components/calendar/settings/MainSettings.vue')
                    },
                    {
                        name: 'calendar-settings-template-menu',
                        path: 'template',
                        component: () => import('./components/calendar/settings/TemplateMenu.vue')
                    },
                    {
                        name: 'calendar-settings-template',
                        path: 'template/:templateId',
                        component: () => import('./components/calendar/settings/TemplateSettings.vue')
                    },
                    {
                        name: 'calendar-settings-host-menu',
                        path: 'host',
                        component: () => import('./components/calendar/settings/HostMenu.vue'),
                    },
                    {
                        name: 'calendar-settings-price-level',
                        path: 'price-level',
                        component: () => import('./components/calendar/settings/PriceLevelSettings.vue'),
                    },
                    {
                        name: 'calendar-settings-rating-menu',
                        path: 'rating',
                        component: () => import('./components/calendar/settings/RatingMenu.vue')
                    },
                ]
            }
        ]
    },
    {
        name: 'create-calendar',
        path: '/calendar/create',
        component: () =>
            import ('./pages/CreateCalendar.vue')
    },
    {
        name: 'calendar-list',
        path: '/calendar/list',
        component: () =>
            import ('./pages/CalendarList.vue'),
        query: {
            q: null,
        },
    },
    {
        name: 'test-card-settings',
        path: '/test/page',
        component: () =>
            import ('./components/calendar/table/SlotSettings.vue')
    },
    {
        name: 'test-auth',
        path: '/test/auth',
        component: () =>
            import ('./pages/test/Auth.vue')
    }
]

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})

export default router
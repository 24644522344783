<template>
    <div class="calendar__item" @click="usage">
        <div class="image__container">
            <span v-if="icon" class="iconify" :data-icon="icon" data-width="27" data-height="27"></span>
        </div>
        <div class="text__container">
            <div class="calendar__title" v-bind:class="{
                'default' : isDefault,
            }">
                {{isDefault ? 'My calendar' : title}}
            </div>
            <div class="description" v-if="description">{{description}}</div>
        </div>
        <div class="selector__container">
            <span v-if="!icon" class="iconify" 
            v-bind:style="{'color' : isSelected?'#0075ff':'grey'}"
            data-icon="codicon:circle" data-width="28" data-height="28">
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Item',
    props: {
        title: String,
        usage: {
            type: Function,
            default: () => {console.log('select calendar')}
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        isDefault: {
            type: Boolean,
            default: false,
        }, 
        description: {
            type: String,
            default: '',
        },
        imageUrl: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.calendar__item{
    height: 40px;
    display: grid;
    grid-template-columns: 40px calc(100% - 40px - 40px - 20px) 40px;
    grid-column-gap: 10px;
    margin: 0 11px;
}

.image__container{
    height: 40px;
    width: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid black;
    border-radius: 20px;
}

.text__container{
    /* padding: 5px 0px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
    height: 40px;
}

.selector__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.calendar__title{
    font-weight: 600;
    font-size: 16px;
}

.calendar__title.default {
    font-weight: 800;
}

</style>
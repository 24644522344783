<template>
    <vue-final-modal
        id="burger-menu"
        v-model="isView"
        @click-outside="closeHandler">
            <div class="burger-menu__content" >
                <div class="modal-cont" v-bind:class="{
                    'mount-animation' : isMountAnimation,
                    'modal-cont__drag-open': isDragOpen,
                    'modal-cont__drag-close': isDragClose,
                }">
                    <div class="selector-container">
                        <div class="selector"></div>
                    </div>
                    <div class="item-cont">
                        <slot></slot>
                    </div>
                </div>
            </div>
    </vue-final-modal>
</template>

<script>
import * as Draggabilly from 'draggabilly/draggabilly';
import BurgerMenuItem from './BurgerMenuItem.vue'

const CLOSE_LIMIT = 140

export default {
    name: 'BurgerMenu',
    components: { BurgerMenuItem },
    props: {
        show: Boolean,
        closer: Function,
    },
    data() {
        return {
            isView: this.show,
            draggabilly: null,

            // Drag animations
            isMountAnimation: false,
            isDragClose: false,
            isDragOpen: false,
        };
    },
    watch: {
        isView(newValue){
            if (newValue){
                this.draggabilly.enable()
            } else {
                this.draggabilly.disable()
            }
        }
    },
    beforeMount() {
        this.isMountAnimation = true
        setTimeout(() => {
            this.isMountAnimation = false
        }, 750)
        

    },
    mounted(){
        let burgerMenuContent = document.getElementsByClassName("burger-menu__content")[0]
        let burgerMenuModal = document.getElementsByClassName("modal-cont")[0]
        this.draggabilly = new Draggabilly(burgerMenuModal, {
            containment: burgerMenuContent,
            handle: ".selector-container",
            grid: [1, this.movementStep * this.oneMinuteHeight]
        })
        this.draggabilly.on("dragMove", this.dragMove)
        this.draggabilly.on("dragEnd", this.dragEnd)
    },

    methods: {
        closeHandler(){
            this.isDragClose = true
            setTimeout(() => {
                this.isDragClose = false
                this.draggabilly.setPosition(0, 200)
                this.closer()
            }, 300)
        },
        dragEnd(){
            if (this.draggabilly.position.y > CLOSE_LIMIT){
                this.closeHandler()
            } else {
                if (!this.isDragOpen){
                    this.isDragOpen = true
                    setTimeout(() => {
                        this.isDragOpen = false
                        this.draggabilly.setPosition(0, 0)
                    }, 300)
                }
            }
        },
    },
}
</script>

<style scoped>
.vfm__content{
    height: calc(100vh + 200px);
}
.modal-cont{
    position:absolute;
    top: 0;
    height: 260px;
    min-height: 60px;
    width: 100%;
    background: white;
    border-radius: 10px 10px 0 0;
}

#burger-menu{
    height: calc(100% + 200px);
}

.burger-menu__content{
    height: 460px;
    width: 100%;
    bottom: 0;
    position: absolute;
}


.mount-animation{
    animation: .75s modal-mounted cubic-bezier(0.22, 0.61, 0.36, 1);
}

@keyframes modal-mounted {
    from {
        top: 260px;
    }
    to{
        top: 0;
    }   
}


.modal-cont__drag-open{
    animation: .3s modal-drag-open;
}

@keyframes modal-drag-open {
    to{
        top: 0;
    }
}

.modal-cont__drag-close{
    animation: .3s modal-drag-close;
}

@keyframes modal-drag-close {
    to{
        top: 260px;
    }
}

.item-cont{
    padding: 20px 3px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 220px;
}

.selector-container{
    height: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 0;
}

.selector{
    height: 3px;
    width: 60px;
    border-radius: 6px;
    background: grey;
}
</style>
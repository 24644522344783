<template>
    <div id='header'>
        <div class='title__container'>
            <span class="open_calendar_menu" v-if="headerTitle" @click="openPersonalCalendarList">
                <div class="title__value">{{headerTitle}}</div>
                <span class="iconify" 
                    data-icon="ic:round-keyboard-arrow-down" 
                    style="color: #969696;" 
                    data-width="22" 
                    data-height="22">
                </span>
            </span>
            <span v-else>
                Hora Guru
            </span>
        </div>
        <div class="reg" v-if="!isAuthPage && isLogin" @click="openBurgerMenu">
            <span class="iconify" data-icon="eva:menu-fill" style="color: #969696;" data-width="28" data-height="21"></span>
        </div>
        <div class="reg" v-if="!isAuthPage && !isLogin">
            <div @click="auth">Authorize</div>
        </div> 

        <CalendarBurgerMenu
            v-if="isLogin && isCalendarPage && showBurgerMenu"
            :show='showBurgerMenu'
            :closer="closeBurgerMenu"
        />
        <DefaultBurgerMenu
            v-if="isLogin && !isCalendarPage && showBurgerMenu"
            :show='showBurgerMenu'
            :closer="closeBurgerMenu"
        />
        <PersonalCalendarList
            v-if="isLogin && showPersonalCalendarList"
            :title="personalCalendarListTitle"
            :show='showPersonalCalendarList'
            :selector="calendarSelector"
            :closer="closePersonalCalendarList"
            :excludeCurrent="excludeCurrent"
            :excludeCreate="excludeCreate"
        />
    </div>
</template>

<script>
import PersonalCalendarList from '../calendar-list/PersonalCalendarList.vue'
import TokenService from '../../service/token-service.js'

import router from '../../router'
import CalendarBurgerMenu from './CalendarBurgerMenu.vue'
import DefaultBurgerMenu from './DefaultBurgerMenu.vue'
import { globalBus } from '../../GlobalEventBus'

export default {
    name:'Header',
    components:{
        PersonalCalendarList,
        CalendarBurgerMenu,
        DefaultBurgerMenu
    },
    computed: {
        headerTitle(){
            if (!this.$store.getters.getDefaultCalendar)
                return undefined

            let isCalendarPage = this.$route.name == "calendar"
            if (!isCalendarPage)
                return "My calendar"

            let currentCalendarUid = this.$route.params.calendarUid
            let managedCalendarUid = Object.keys(this.$store.getters.userManagedCalendarList)
            let defaultCalendarUid = this.$store.getters.getDefaultCalendar

            if (currentCalendarUid == defaultCalendarUid) {
                return "My calendar"
            } else if (managedCalendarUid.includes(currentCalendarUid)){
                return this.lastCalendarUid
            } else {
                return "My calendar"
            }
        },
        lastCalendarUid(){
            return this.$store.getters.calendarUid
        },
        isLogin(){
            return this.$store.getters.userIsLogin
        },
        isCalendarPage(){
            return this.$route.name == "calendar"
        }
    },
    beforeMount() {
        console.log('create header')
    },
    created(){
        globalBus.$on('page-header', 'open-personal-calendar-list', (payload) => {
            this.calendarSelector = (uid) => {
                this.calendarSelector = null
                this.excludeCurrent = false
                this.excludeCreate = false
                this.personalCalendarListTitle = ""
                return payload.selector(uid)
            }
            this.excludeCurrent = payload.excludeCurrent
            this.showPersonalCalendarList = true
            this.excludeCreate = payload.excludeCreate
            this.personalCalendarListTitle = payload.title
        })
    },
    data(){
        return {
            showBurgerMenu: false,
            showPersonalCalendarList: false,
            personalCalendarListTitle: "",
            excludeCurrent: false,
            excludeCreate: false,
            calendarSelector: null, 
        }
    },
    props:{
        isAuthPage:{
            type: Boolean,
            default: false,           
        }
    },
    methods: {
        auth() {
            router.push({name:'auth'})
        },

        openBurgerMenu(){
            this.showBurgerMenu = true
        },
        closeBurgerMenu(){
            this.showBurgerMenu = false
        },

        openPersonalCalendarList(){
            this.showPersonalCalendarList = true
        },
        closePersonalCalendarList(){
            this.personalCalendarListTitle = ""
            this.showPersonalCalendarList = false
        },
    },    
}
</script>

<style scoped>
#header{
    position: fixed;
    top:0;
    z-index: 6;
    height: fit-content;
    background: lightgray;
    font-size: 12px;
    font-weight: 400;
    height: 32px;
    width: 100%;
}
.open_calendar_menu{
    display: grid;
    grid-template-columns: calc(100% - 18px) 18px;
}
.title__container{
    float: left;
    width: fit-content;
    margin-left: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
}

.title__value{
    display: flex;
    align-items: center;
}
.reg{
    float: right;
    color: #4773e5;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}
</style>
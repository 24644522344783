<template>
    <div class="calendar_burger_menu">
        <BurgerMenu :show="show" :closer="closer">
            <BurgerMenuItem
                v-for="(item, index) in getItems()"
                :key="'menu-item-' + index"

                :title="item.title"
                :titleColor="item.titleColor"
                :titleStyle="item.titleStyle"

                :icon="item.icon"
                :iconColor="item.iconColor"
                :iconSize="item.iconSize"

                :usage="item.usage"/>
        </BurgerMenu>
    </div>
</template>

<script>
import { ADMIN_MEMBERSHIP_LEVEL, OWNER_MEMBERSHIP_LEVEL } from '../../scripts/membershipLevels';
import BurgerMenu from '../burger-menu/BurgerMenu.vue';
import BurgerMenuItem from '../burger-menu/BurgerMenuItem.vue';

import router from '../../router';
import AuthService from '../../service/auth-service';

export default {
    name: 'CalendarBurgerMenu',
    components: {
        BurgerMenu,
        BurgerMenuItem
    },
    props: {
        show: Boolean,
        closer: Function,
    },
    methods: {
        getItems(){
            let membershipLevel = this.$store.getters.getMembershipLevel
            let calendarTitle = this.$store.getters.getTitle

            let calendarSettings = {
                title : `Calendar settings "${calendarTitle}"`,
                icon : "fluent:settings-32-regular",
                usage : () => {router.push({name: "calendar-settings-menu"})},
            }
        
            let items = [
                {title: "Account settings", icon: "mdi:tooltip-user"},
                // calendarSettings if calendar is owner's or admin's
                {title: "Messages from developer", icon:"ic:round-mail"},
                {
                    title: "Exit",
                    titleColor: "red",
                    icon: "bx:exit",
                    iconColor: "red",
                    usage: this.sighOut,
                },
            ]
                
            if (membershipLevel == ADMIN_MEMBERSHIP_LEVEL || membershipLevel == OWNER_MEMBERSHIP_LEVEL){
                items.splice(1, 0, calendarSettings)
            }

            return items
        },
        async sighOut(){
            this.$store.dispatch("delete-user-data")
            AuthService.signOut()
        },
    },
};
</script>

<style scoped>

</style>
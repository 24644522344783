import Vue from 'vue';
import { getFormatSeparator } from '../../scripts/date.js';
import CMS from '../../service/cms/service.js'

import TokenService from '../../service/token-service.js';
const getDefaultState = () => {
    return {
        isLogin: TokenService.tokenIsExists(),
        id: -1,
        countryId: -1,
        timezoneId: -1,
        shortName: "",
        firstName: "",
        lastName:  "",
        email: "",
        imageUrl: "",
        dateFormat: "dd.MM.YYYY",
        defaultCalendar: "", 
        workdayCalendarId: -1,
        calendars: {
            managed: {},
            public: {}
        },
    }
}
export default {
    state: getDefaultState(),
    mutations: {
        'set-user-info': (state, payload) => {
            Object.keys(payload).forEach((item) => {
                if (item == 'calendars') {
                    let calendars = payload[item] || []
                    calendars.forEach(calendar => {
                        state.calendars.managed[calendar.uid] = calendar
                        if (calendar.isDefault) {
                            state.defaultCalendar = calendar.uid
                        }
                    })
                    return
                }
                if (typeof payload[item] == "boolean") {
                    state[item] = payload[item]
                } else {
                    state[item] = payload[item] || state[item] // new value or default
                }
            })
        },
        'set-calendar': (state, payload) => {
            let uid = payload.uid
            let calendar = payload.calendar

            state.calendars.public[uid] = calendar
        },
        'clear-user-info': (state, _) => {
            let clearedState = getDefaultState()
            Object.keys(clearedState).forEach(field => {
                state[field] = clearedState[field]
            })
            state.isLogin = false
        },
        'add-managed-calendar': (state, {calendar, uid}) =>  {
            Vue.set(state.calendars.managed, uid, calendar )
        }
    },
    getters: {
        userTimezoneId(state){
            return state.timezoneId
        },

        userCountryId(state){
            return state.countryId
        },

        userIsLogin(state){
            return state.isLogin
        },

        userId(state){
            return state.id
        },

        getDefaultCalendar(state) {
            return state.defaultCalendar
        },
        dateFormat(state){
            let sepr = getFormatSeparator(state.dateFormat)
            let parts = state.dateFormat.split(sepr)
            let places = {}
            parts.forEach((datePart, index) => {
                places[datePart] = index
            });

            return {
                format: state.dateFormat,
                places: places,
                sepr: sepr
            }
        },
        getPublicCalendar: (state) => (uid) => {
            return Object.values(state.calendars.public).find(calendar => {
                return calendar.uid = uid  
            })
        },
        userManagedCalendarList(state) {
            return state.calendars.managed
        }
    },
    actions: {
        "delete-user-data": async(context, _ ) => {
            context.commit('clear-user-info')
        },
        "set-user-data": async(context, payload) => {
            if (payload.isLogin) {
                context.commit('set-user-info', payload)
            } else {
                context.commit('clear-user-info')
            }
        },
        "set-calendar": async(context, { uid, calendar }) => {
            context.commit("set-calendar", { uid, calendar })
        },
        'add-managed-calendar' : ({commit}, payload) => {
            commit('add-managed-calendar', payload)
        },
        "update-user-data": async(context, payload) => {
            if (payload) {
                context.commit('set-user-info', payload)
                return
            }
            return CMS.user.getInfo()
                .then(({data}) => {
                    let userInfo = {
                        id: data.id,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,

                        shortName: data.shortName,
                        calendars: data.calendars,

                        countryId: data.countryId,
                        timezoneId: data.timezoneId,
                        workdayCalendarId: data.workdayCalendarId,

                        isLogin: true,
                    }
                    context.commit('set-user-info', userInfo)
                })
                .catch(() => {})
        }
    }
}

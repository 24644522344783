import Calendar from "./modules/calendar.js";
import Template from "./modules/template.js";
import Rating from "./modules/rating.js"
import Prices from "./modules/prices.js"
import Slots from "./modules/slots.js";
import User from "./modules/user.js";
import Geo from "./modules/geo.js"

class CMS {

    // Timezones, countries, workdayCalendars api
    static geo = Geo 

    // User profile api
    static user = User

    // Calendar api
    static calendar = Calendar

    // Slots api
    static slots = Slots

    // Templates api
    static template = Template

    // Rating api
    static rating = Rating

    // PriceLevel api
    static prices = Prices
}

export default CMS
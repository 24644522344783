import axios from "axios"
import client, { errorView } from "../../client"


class Calendar{
    static getByUid(calendarUid, isAuthorized=true) {
        if (!isAuthorized){
            return axios.get(`/cms/api/v1/calendars/${calendarUid}`).then((response) => response.data)
            .catch(err => {
                console.log('err :>> ', err);
                if (err.response.status == 400) 
                    return
                errorView("Get calendar by uid", err)
            })
        }

        return client.get(`/cms/api/v1/calendars/${calendarUid}`).then((response) => response.data)
        .catch(err => {
            if (err.response.status == 400) 
                return {err}
            errorView("Get calendar by uid", err)
        })
    }
    static listBySearch(search, offset, limit) {
        let params = `q=${search}&offset=${offset}&limit=${limit}`
        return client.get(`/cms/api/v1/calendars?${params}`).then((response) => response.data)
        .catch(err => errorView("Get calendar list by search", err))
    }

    static create(data) {
        return client.post(`/cms/api/v1/calendars`, data).then((response) => response.data)
        .catch(err => errorView("Create calendar ", err))
    }
}

export default Calendar